<div class="container">
  <!-- section title -->
  <div class="row quote">
    <p class="font-open-sans-bold rounded">BECOME PART OF THE MOSAIC</p>
    <p class="font-open-sans-bold rounded">FAMILY, BY PARTNERING WITH</p>
    <p class="font-open-sans-bold rounded">US THROUGH VOLUNTEERING,</p>
    <p class="font-open-sans-bold rounded">OR BY DONATING TOWARDS</p>
    <p class="font-open-sans-bold rounded">OUR PROGRAMMES.</p>
  </div>
  <div class="spacer-60"></div>

  <div class="row blog-wrapper"></div>
  <div class="spacer-30"></div>
</div>
