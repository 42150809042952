<div class="container">
  <!-- section title -->
  <div class="row">
    <h2 class="section-title font-open-sans-bold rounded section-header" style="text-align: right;">OUR VISION & GROWTH STRATEGY</h2>
  </div>
  <div class="spacer-60"></div>
  <div class="row margin-left portfolio-wrapper">
    <!-- portfolio item -->
    <div class="col row bg-box shadow-dark padding-30">
      <p style="font-size: 28px; color: #F16725;">V I S I O N</p>
      <div class="font-open-sans-light" style="color: black">
        <p>
          Our vision is for every orphaned and vulnerable child in South Africa to have a loving and caring family, where they can be equipped to realise their
          God-given potential.
        </p>
        <p>
          We believe that a safe, supportive and healthy family environment is the best space for kids to thrive and we therefore partner with families taking
          care of orphaned and vulnerable children (OVC) through the Mosaic Model.
        </p>
      </div>

      <p style="font-size: 28px; color: #F16725;">G R O W T H <span class="margin-left"> S T R A T E G Y</span></p>
      <div class="font-open-sans-light" style="color: black">
        <p>
          We know that one organisation will not be able to serve all orphaned and vulnerable children and their families in South Africa, but we believe that
          through partnerships we can achieve our goal. Organisations or individuals partner with us, by implementing the Mosaic Model in their local community.
          Through these partnerships we will be able to see significantly more OVC and their caregivers reach their God-given potential.
        </p>

        <p>
          Mosaic Deben is the first such partnership. They are an independently registered Mosaic Centre implementing the Mosaic Model with all its different
          programmes in Deben in the Northern Cape. We support them in the following ways:
        </p>
        <ul>
          <li>
            Access to all Mosaic’s operational resources, including the content of the different programmes and individual consultation with our Operations
            Officer
          </li>
          <li>
            Access to our administrative framework which include policies, structures and procedure to support operations in a responsible and transparent
            manner.
          </li>
          <li>Access to various training and development opportunities.</li>
        </ul>
      </div>
      <p class="font-open-sans-light-italic">If you would like to learn more about becoming an independently registered organisation, you can contact us.</p>
    </div>
  </div>
  <div class="spacer-30"></div>
</div>
