<div class="container">
  <!-- section title -->
  <div class="row">
    <h2 class="section-title wow fadeInUp rounded section-header"><span class="font-open-sans-light">MOSAIC</span><span class="font-open-sans-bold"> LOCATIONS</span></h2>
  </div>
  <div class="spacer-60"></div>

  <div class="row blog-wrapper"></div>
  <div class="row margin-left portfolio-wrapper">
    <!-- portfolio item -->
    <div class="col row bg-box shadow-dark padding-30">
      <img class="margin-img" src="assets/images/locations.png" />
    </div>
  </div>
  <div class="spacer-30"></div>
</div>
