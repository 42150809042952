import { Injectable } from '@angular/core';
import { IMosaicStatisticWrapper } from 'src/app/interfaces';

import * as statisticData from 'src/assets/data/statistics.json';

@Injectable()
export class MosaicDataService {
  public mosaicChartData(): IMosaicStatisticWrapper {
    const objectData: IMosaicStatisticWrapper = (statisticData as any).default;
    return objectData;
  }


}
