import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-volunteering-quote',
  templateUrl: './volunteering-quote.component.html',
  styleUrls: ['./volunteering-quote.component.scss']
})
export class VolunteeringQuoteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  scrollTo(section) {
    const sectionHtml = document.querySelector('#' + section);
    if (sectionHtml !== null) {
      sectionHtml.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }

}
