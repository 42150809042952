<div class="container">
  <!-- section title -->
  <div class="row">
    <h2 class="section-title wow fadeInUp font-open-sans-bold rounded section-header">MEASURING OUR IMPACT</h2>
  </div>
  <div class="spacer-60"></div>

  <div class="row blog-wrapper"></div>
  <div class="row margin-left portfolio-wrapper">
    <!-- portfolio item -->
    <div class="col row bg-box shadow-dark padding-30">
      <div class="font-open-sans-light" style="color: black">
        <p>
          At Mosaic we believe impact should be quantifiable. Because we feel so strong about this, we have developed a software system that measures 8
          different wellbeing areas of each child at Mosaic. These 8 wellbeing areas, or domains as we call them, are calculated by tracking more than 80 data
          points on each child on a monthly basis.
        </p>
        <p>
          Having this data helps us to decide how to best support each individual child and caretaker, focusing our time and limited resources on the things
          that is going to have the biggest possible impact. Over time, this data also helps us to see which of our programmes are working and which ones are
          not, so that we can redirect these programmes toward higher impact in the lives of our children and caretakers.
        </p>
      </div>
      <!--Food & Nutrition -->
      <div class="col-12 col-sm-12 col-lg-4" style="text-align: center">
        <p class="font-open-sans-semibold">AGGREGATE VALUES</p>
        <div class="values font-soulmaze">
          <span class="number-center">88%</span>
          <div class="arrow-up"></div>
          <div class="square inside-arrow-value">5%</div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-lg-4" style="text-align: center">
        <p class="font-open-sans-semibold">DOMAIN</p>
        <img class="domain-image" src="assets/images/Mosaic Icons-11.png" />
      </div>
      <!-- Big Screen -->
      <div class="col-12 col-sm-12 col-lg-4 d-none d-sm-none d-md-none d-lg-block d-xl-block" style="text-align: center">
        <p class="font-open-sans-semibold">SUPPORTING PROGRAMMES</p>
        <ng-container *ngIf="readMoreFoodAndNutrition">
          <a (click)="onClick('foodAndNutrition')" type="button" class="arrow-right"></a>
          <p (click)="onClick('foodAndNutrition')" type="button" class="square-label-right font-open-sans-light" style="color: white">Read More</p>
        </ng-container>
        <ng-container *ngIf="!readMoreFoodAndNutrition">
          <i (click)="onClick('foodAndNutrition')" type="button" class="arrow-left"></i>
          <p (click)="onClick('foodAndNutrition')" type="button" class="square-label-left font-open-sans-light" style="color: white">-</p>
        </ng-container>
        <ng-container *ngIf="visibleFoodAndNutrition">
          <div @inOut class="programmes-box rounded font-open-sans-light">
            <ol class="inner-programmes-box">
              <li>Food Parcels – food security.</li>
              <li>Cooked Meals provided at afterschool programmes.</li>
              <li>Coaching: where we emphasise the importance of good nutrition.</li>
              <li>Tracking the food and nutrition data on OVCmeasure.</li>
              <li>Sustainable food & income generation programmes.</li>
            </ol>
          </div>
        </ng-container>
      </div>
      <!-- Small Screen -->
      <div class="col-12 col-sm-12 col-lg-4 d-block d-xl-none d-lg-none" style="text-align: center">
        <p class="font-open-sans-semibold">SUPPORTING PROGRAMMES</p>
        <ng-container>
          <div class="programmes-box rounded font-open-sans-light">
            <ol class="inner-programmes-box">
              <li>Food Parcels – food security.</li>
              <li>Cooked Meals provided at afterschool programmes.</li>
              <li>Coaching: where we emphasize the importance of good nutrition.</li>
              <li>Tracking the food and nutrition data on OVCmeasure.</li>
              <li>Sustainable food & income generation programmes.</li>
            </ol>
          </div>
        </ng-container>
      </div>
      <!--Shelter & Care -->
      <div class="col-12 col-sm-12 col-lg-4" style="text-align: center">
        <p class="font-open-sans-semibold">AGGREGATE VALUES</p>
        <div class="values font-soulmaze">
          <span class="number-center">88%</span>
          <div class="arrow-up"></div>
          <div class="square inside-arrow-value">4%</div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-lg-4" style="text-align: center">
        <p class="font-open-sans-semibold">DOMAIN</p>
        <img class="domain-image" src="assets/images/Mosaic Icons-10.png" />
      </div>
      <!-- Big Screen -->
      <div class="col-12 col-sm-12 col-lg-4 d-none d-sm-none d-md-none d-lg-block d-xl-block" style="text-align: center">
        <p class="font-open-sans-semibold">SUPPORTING PROGRAMMES</p>
        <ng-container *ngIf="readMoreShelterAndCare">
          <a (click)="onClick('shelterAndCare')" type="button" class="arrow-right"></a>
          <p (click)="onClick('shelterAndCare')" type="button" class="square-label-right font-open-sans-light" style="color: white">Read More</p>
        </ng-container>
        <ng-container *ngIf="!readMoreShelterAndCare">
          <i (click)="onClick('shelterAndCare')" type="button" class="arrow-left"></i>
          <p (click)="onClick('shelterAndCare')" type="button" class="square-label-left font-open-sans-light" style="color: white">-</p>
        </ng-container>
        <ng-container *ngIf="visibleShelterAndCare">
          <div @inOut class="programmes-box rounded font-open-sans-light">
            <ol class="inner-programmes-box">
              <li>Building a house.</li>
              <li>Assistance with RDP-Housing.</li>
              <li>Renovation of current house.</li>
              <li>Implementing a trust based relational intervention approach in all our programmes.</li>
            </ol>
          </div>
        </ng-container>
      </div>
      <!-- Small Screen -->
      <div class="col-12 col-sm-12 col-lg-4 d-block d-xl-none d-lg-none" style="text-align: center">
        <p class="font-open-sans-semibold">SUPPORTING PROGRAMMES</p>
        <div class="programmes-box rounded font-open-sans-light">
          <ol class="inner-programmes-box">
            <li>Building a house.</li>
            <li>Assistance with RDP-Housing.</li>
            <li>Renovation of current house.</li>
            <li>Implementing a trust based relational intervention approach in all our programmes.</li>
          </ol>
        </div>
      </div>
      <!-- Protection -->
      <div class="col-12 col-sm-12 col-lg-4" style="text-align: center">
        <p class="font-open-sans-semibold">AGGREGATE VALUES</p>
        <div class="values font-soulmaze">
          <span class="number-center">93%</span>
          <div class="arrow-up"></div>
          <div class="square inside-arrow-value">9%</div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-lg-4" style="text-align: center">
        <p class="font-open-sans-semibold">DOMAIN</p>
        <img class="domain-image" src="assets/images/Mosaic Icons-12.png" />
      </div>
      <!-- Big Screen -->
      <div class="col-12 col-sm-12 col-lg-4 d-none d-sm-none d-md-none d-lg-block d-xl-block" style="text-align: center">
        <p class="font-open-sans-semibold">SUPPORTING PROGRAMMES</p>
        <ng-container *ngIf="readMoreProtection">
          <a (click)="onClick('protection')" type="button" class="arrow-right"></a>
          <p (click)="onClick('protection')" type="button" class="square-label-right font-open-sans-light" style="color: white">Read More</p>
        </ng-container>
        <ng-container *ngIf="!readMoreProtection">
          <i (click)="onClick('protection')" type="button" class="arrow-left"></i>
          <p (click)="onClick('protection')" type="button" class="square-label-left font-open-sans-light" style="color: white">-</p>
        </ng-container>
        <ng-container *ngIf="visibleProtection">
          <div @inOut class="programmes-box rounded font-open-sans-light">
            <ol class="inner-programmes-box">
              <li>Providing access to legal advice.</li>
              <li>Identification and interventions on cases of possible abuse or neglect.</li>
              <li>Assistance with ID documents, driver’s license, and the drafting of a Will.</li>
            </ol>
          </div>
        </ng-container>
      </div>
      <!-- Small Screen -->
      <div class="col-12 col-sm-12 col-lg-4 d-block d-xl-none d-lg-none" style="text-align: center">
        <p class="font-open-sans-semibold">SUPPORTING PROGRAMMES</p>
        <div class="programmes-box rounded font-open-sans-light">
          <ol class="inner-programmes-box">
            <li>Providing access to legal advice.</li>
            <li>Identification and interventions on cases of possible abuse or neglect.</li>
            <li>Assistance with ID documents, driver’s license, and the drafting of a Will.</li>
          </ol>
        </div>
      </div>
      <!-- Health -->
      <div class="col-12 col-sm-12 col-lg-4" style="text-align: center">
        <p class="font-open-sans-semibold">AGGREGATE VALUES</p>
        <div class="values font-soulmaze">
          <span class="number-center">90%</span>
          <div class="arrow-up"></div>
          <div class="square inside-arrow-value">3%</div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-lg-4" style="text-align: center">
        <p class="font-open-sans-semibold">DOMAIN</p>
        <img class="domain-image" src="assets/images/Mosaic Icons-13.png" />
      </div>
      <!-- Big Screen -->
      <div class="col-12 col-sm-12 col-lg-4 d-none d-sm-none d-md-none d-lg-block d-xl-block" style="text-align: center">
        <p class="font-open-sans-semibold">SUPPORTING PROGRAMMES</p>
        <ng-container *ngIf="readMoreHealth">
          <a (click)="onClick('health')" type="button" class="arrow-right"></a>
          <p (click)="onClick('health')" type="button" class="square-label-right font-open-sans-light" style="color: white">Read More</p>
        </ng-container>
        <ng-container *ngIf="!readMoreHealth">
          <i (click)="onClick('health')" type="button" class="arrow-left"></i>
          <p (click)="onClick('health')" type="button" class="square-label-left font-open-sans-light" style="color: white">-</p>
        </ng-container>
        <ng-container *ngIf="visibleHealth">
          <div @inOut class="programmes-box rounded font-open-sans-light">
            <ol class="inner-programmes-box">
              <li>Ensure that beneficiaries have access to basic healthcare services.</li>
              <li>Track beneficiary wellbeing indicators.</li>
              <li>Arrange necessary interventions for families.</li>
            </ol>
          </div>
        </ng-container>
      </div>
      <!-- Small Screen -->
      <div class="col-12 col-sm-12 col-lg-4 d-block d-xl-none d-lg-none" style="text-align: center">
        <p class="font-open-sans-semibold">SUPPORTING PROGRAMMES</p>
        <div class="programmes-box rounded font-open-sans-light">
          <ol class="inner-programmes-box">
            <li>Ensure that beneficiaries have access to basic healthcare services.</li>
            <li>Track beneficiary wellbeing indicators.</li>
            <li>Arrange necessary interventions for families.</li>
          </ol>
        </div>
      </div>
      <!-- Psychosocial -->
      <div class="col-12 col-sm-12 col-lg-4" style="text-align: center">
        <p class="font-open-sans-semibold">AGGREGATE VALUES</p>
        <div class="values font-soulmaze">
          <span class="number-center">91%</span>
          <div class="arrow-up"></div>
          <div class="square inside-arrow-value">3%</div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-lg-4" style="text-align: center">
        <p class="font-open-sans-semibold">DOMAIN</p>
        <img class="domain-image" src="assets/images/Mosaic Icons-14.png" />
      </div>
      <!-- Big Screen -->
      <div class="col-12 col-sm-12 col-lg-4 d-none d-sm-none d-md-none d-lg-block d-xl-block" style="text-align: center">
        <p class="font-open-sans-semibold">SUPPORTING PROGRAMMES</p>
        <ng-container *ngIf="readMorePsychosocial">
          <a (click)="onClick('psychosocial')" type="button" class="arrow-right"></a>
          <p (click)="onClick('psychosocial')" type="button" class="square-label-right font-open-sans-light" style="color: white">Read More</p>
        </ng-container>
        <ng-container *ngIf="!readMorePsychosocial">
          <i (click)="onClick('psychosocial')" type="button" class="arrow-left"></i>
          <p (click)="onClick('psychosocial')" type="button" class="square-label-left font-open-sans-light" style="color: white">-</p>
        </ng-container>
        <ng-container *ngIf="visiblePsychosocial">
          <div @inOut class="programmes-box rounded font-open-sans-light">
            <ol class="inner-programmes-box">
              <li>Life coaching.</li>
              <li>Counselling.</li>
              <li>Self-development opportunities through our programmes.</li>
            </ol>
          </div>
        </ng-container>
      </div>
      <!-- Small Screen -->
      <div class="col-12 col-sm-12 col-lg-4 d-block d-xl-none d-lg-none" style="text-align: center">
        <p class="font-open-sans-semibold">SUPPORTING PROGRAMMES</p>
        <div class="programmes-box rounded font-open-sans-light">
          <ol class="inner-programmes-box">
            <li>Life coaching.</li>
            <li>Counselling.</li>
            <li>Self-development opportunities through our programmes.</li>
          </ol>
        </div>
      </div>
      <!-- Education and skills -->
      <div class="col-12 col-sm-12 col-lg-4" style="text-align: center">
        <p class="font-open-sans-semibold">AGGREGATE VALUES</p>
        <div class="values font-soulmaze">
          <span class="number-center">83%</span>
          <div class="square-down inside-arrow-value">1%</div>
          <div class="arrow-down"></div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-lg-4" style="text-align: center">
        <p class="font-open-sans-semibold">DOMAIN</p>
        <img class="domain-image" src="assets/images/Mosaic Icons-15.png" />
      </div>
      <!-- Big Screen -->
      <div class="col-12 col-sm-12 col-lg-4 d-none d-sm-none d-md-none d-lg-block d-xl-block" style="text-align: center">
        <p class="font-open-sans-semibold">SUPPORTING PROGRAMMES</p>
        <ng-container *ngIf="readMoreEducationAndSkills">
          <a (click)="onClick('educationAndSkills')" type="button" class="arrow-right"></a>
          <p (click)="onClick('educationAndSkills')" type="button" class="square-label-right font-open-sans-light" style="color: white">Read More</p>
        </ng-container>
        <ng-container *ngIf="!readMoreEducationAndSkills">
          <i (click)="onClick('educationAndSkills')" type="button" class="arrow-left"></i>
          <p (click)="onClick('educationAndSkills')" type="button" class="square-label-left font-open-sans-light" style="color: white">-</p>
        </ng-container>
        <ng-container *ngIf="visibleEducationAndSkills">
          <div @inOut class="programmes-box rounded font-open-sans-light">
            <ol class="inner-programmes-box">
              <li><span class="underline">After-School Programmes:</span> <br> A-Z Reading, Numbersense, Journaling, Homework Assistance & Tutoring</li>
              <li><span class="underline">Talent Development:</span><br> Choir , Chess, Gym, Hip Hop dancing, Musical instruments, Soccer & Other sports</li>
              <li>
                <span class="underline">Skills Training:</span> <br>Fun & creativity, arts & crafts, speeches, team-building games, computer programmes (ICT), other exposure activities, outings,
                educational videos & documentaries. Speakers on different life skills and professions, sexual education, strengths assessments, and career
                tests.
              </li>
            </ol>
          </div>
        </ng-container>
      </div>
      <!-- Small Screen -->
      <div class="col-12 col-sm-12 col-lg-4 d-block d-xl-none d-lg-none" style="text-align: center">
        <p class="font-open-sans-semibold">SUPPORTING PROGRAMMES</p>
        <div class="programmes-box rounded font-open-sans-light">
          <ol class="inner-programmes-box">
            <li>After-School Programmes: A-Z Reading, Numbersense, Journaling, Homework Assistance & Tutoring</li>
            <li>Talent Development: Choir , Chess, Gym, Hip Hop dancing, Musical instruments, Soccer & Other sports</li>
            <li>
              Skills Training: Fun & creativity, arts & crafts, speeches, team-building games, computer programmes (ICT), other exposure activities, outings,
              educational videos & documentaries. Speakers on different life skills and professions, sexual education, strengths assessments, and career tests.
            </li>
          </ol>
        </div>
      </div>
      <!-- Economic -->
      <div class="col-12 col-sm-12 col-lg-4" style="text-align: center">
        <p class="font-open-sans-semibold">AGGREGATE VALUES</p>
        <div class="values font-soulmaze">
          <span class="number-center">64%</span>
          <div class="square-down inside-arrow-value">3%</div>
          <div class="arrow-down"></div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-lg-4" style="text-align: center">
        <p class="font-open-sans-semibold">DOMAIN</p>
        <img class="domain-image" src="assets/images/Mosaic Icons-17.png" />
      </div>
      <!-- Big Screen -->
      <div class="col-12 col-sm-12 col-lg-4 d-none d-sm-none d-md-none d-lg-block d-xl-block" style="text-align: center">
        <p class="font-open-sans-semibold">SUPPORTING PROGRAMMES</p>
        <ng-container *ngIf="readMoreEconomic">
          <a (click)="onClick('economic')" type="button" class="arrow-right"></a>
          <p (click)="onClick('economic')" type="button" class="square-label-right font-open-sans-light" style="color: white">Read More</p>
        </ng-container>
        <ng-container *ngIf="!readMoreEconomic">
          <i (click)="onClick('economic')" type="button" class="arrow-left"></i>
          <p (click)="onClick('economic')" type="button" class="square-label-left font-open-sans-light" style="color: white">-</p>
        </ng-container>
        <ng-container *ngIf="visibleEconomic">
          <div @inOut class="programmes-box rounded font-open-sans-light">
            <ol class="inner-programmes-box">
              <li>Financial literacy programmes.</li>
              <li>Development of entrepreneurial skills.</li>
              <li>Job Creation.</li>
              <li>Learnerships.</li>
            </ol>
          </div>
        </ng-container>
      </div>
      <!-- Small Screen -->
      <div class="col-12 col-sm-12 col-lg-4 d-block d-xl-none d-lg-none" style="text-align: center">
        <div class="programmes-box rounded font-open-sans-light">
          <p class="font-open-sans-semibold">SUPPORTING PROGRAMMES</p>
          <ol class="inner-programmes-box">
            <li>Financial literacy programmes.</li>
            <li>Development of entrepreneurial skills.</li>
            <li>Job Creation.</li>
            <li>Learnerships.</li>
          </ol>
        </div>
      </div>
      <!-- Spiritual and community -->
      <div class="col-12 col-sm-12 col-lg-4" style="text-align: center">
        <p class="font-open-sans-semibold">AGGREGATE VALUES</p>
        <div class="values font-soulmaze">
          <span class="number-center">85%</span>
          <div class="square-down inside-arrow-value">5%</div>
          <div class="arrow-down"></div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-lg-4" style="text-align: center">
        <p class="font-open-sans-semibold">DOMAIN</p>
        <img class="domain-image" src="assets/images/Mosaic Icons-16.png" />
      </div>
      <!-- Big Screen -->
      <div class="col-12 col-sm-12 col-lg-4 d-none d-sm-none d-md-none d-lg-block d-xl-block" style="text-align: center">
        <p class="font-open-sans-semibold">SUPPORTING PROGRAMMES</p>
        <ng-container *ngIf="readMoreSpiritualAndCommunity">
          <a (click)="onClick('spiritualAndCommunity')" type="button" class="arrow-right"></a>
          <p (click)="onClick('spiritualAndCommunity')" type="button" class="square-label-right font-open-sans-light" style="color: white">Read More</p>
        </ng-container>
        <ng-container *ngIf="!readMoreSpiritualAndCommunity">
          <i (click)="onClick('spiritualAndCommunity')" type="button" class="arrow-left"></i>
          <p (click)="onClick('spiritualAndCommunity')" type="button" class="square-label-left font-open-sans-light" style="color: white">-</p>
        </ng-container>
        <ng-container *ngIf="visibleSpiritualAndCommunity">
          <div @inOut class="programmes-box rounded font-open-sans-light">
            <ol class="inner-programmes-box">
              <li>Bible Studies.</li>
              <li>Church.</li>
              <li>Family Meetings.</li>
              <li>Camps.</li>
              <li>Support Groups</li>
            </ol>
          </div>
        </ng-container>
      </div>
      <!-- Small Screen -->
      <div class="col-12 col-sm-12 col-lg-4 d-block d-xl-none d-lg-none" style="text-align: center">
        <p class="font-open-sans-semibold">SUPPORTING PROGRAMMES</p>
        <div class="programmes-box rounded font-open-sans-light">
          <ol class="inner-programmes-box">
            <li>Bible Studies.</li>
            <li>Church.</li>
            <li>Family Meetings.</li>
            <li>Camps.</li>
            <li>5. Support Groups</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  <!-- row end -->
</div>
<div class="spacer-30"></div>
