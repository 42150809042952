<div class="container">
  <!-- section title -->
  <div class="row">
    <h2 class="section-title wow fadeInUp font-open-sans-bold rounded section-header">FINANCE</h2>
  </div>
  <div class="spacer-60"></div>
  <div class="row margin-left portfolio-wrapper">
  <div class="col row bg-box  shadow-dark padding-30">
  <!-- buttons -->
  <div class="col-sm-12">
    <div class="button-center">
      <a class="btn btn-default btn-color" (click)="loadSelectedChart(1)">Rand</a>
      <a class="btn btn-default btn-color" (click)="loadSelectedChart(2)">Dollar</a>
      <a class="btn btn-default btn-color" (click)="loadSelectedChart(3)">Euro</a>
    </div>
  </div>

  <div class="col-sm-12">
    <table class="table table-bordered table-hover table-striped" style="background-color: white;">
      <tr>
        <th></th>
        <th class="text-right">2019</th>
        <th class="text-right">2020</th>
        <th class="text-right">2021</th>
      </tr>
      <tbody>
        <ng-container *ngFor="let entry of selectedChartEntries;">
        <tr>
          <td  [ngStyle]="{'color': '#fff', 'background-color': entry['color']}" >{{ entry.name }}</td>
          <td class="text-right" *ngFor="let yearData of entry['data'];">{{ selectedCurrencySymbol }} {{ yearData[0] }}</td>
        </tr>
      </ng-container>

      <tr>
        <td>TOTAL</td>
        <td class="text-right">{{ selectedTotal2019 }}</td>
        <td class="text-right">{{ selectedTotal2020 }}</td>
        <td class="text-right">{{ selectedTotal2021 }}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="row full-row chart-spacing manual-margin margin-left">
    <div class="col-sm-12 margin-top">
      <app-bar-charts *ngIf="isChartVisible" height="800" [barChartEntries]="selectedChartEntries" [currencySymbol]="selectedCurrencySymbol"></app-bar-charts>
    </div>

    <!-- OPEX  -->
    <!-- Small Screen -->
    <div class="col-sm-12 margin-top d-block d-xl-none d-lg-none d-md-none">
      <app-opex-donut-chart *ngIf="isChartVisible" [subheaderY] = "-100" [opexData]="selectedOpexCurrency" [currencySymbol]="selectedCurrencySymbol" [subheader]="subheaderOpex"></app-opex-donut-chart>
    </div>
    <!-- Big Screen -->
    <div class="col-sm-12 margin-top d-none d-sm-none d-md-block d-lg-block d-xl-block">
      <app-opex-donut-chart *ngIf="isChartVisible" [subheaderY] = "-20" [opexData]="selectedOpexCurrency" [currencySymbol]="selectedCurrencySymbol" [subheader]="subheaderOpex"></app-opex-donut-chart>
    </div>

    <!-- CAPEX -->
    <!-- Small Screen -->
    <div class="col-sm-12 margin-top d-block d-xl-none d-lg-none d-md-none">
      <app-capex-donut-chart *ngIf="isChartVisible" [subheaderY] = "-100" [capexData]="selectedCapexCurrency" [currencySymbol]="selectedCurrencySymbol" [subheader]="subheaderCapex"></app-capex-donut-chart>
    </div>
    <!-- Big Screen -->
    <div class="col-sm-12 margin-top d-none d-sm-none d-md-block d-lg-block d-xl-block">
      <app-capex-donut-chart *ngIf="isChartVisible" [subheaderY] = "-20" [capexData]="selectedCapexCurrency" [currencySymbol]="selectedCurrencySymbol" [subheader]="subheaderCapex"></app-capex-donut-chart>
    </div>

    <!-- Assets -->
    <!-- Small Screen -->
    <div class="col-sm-12 margin-top d-block d-xl-none d-lg-none d-md-none">
      <app-assets-donut-chart *ngIf="isChartVisible" [subheaderY] = "-100" [assetsData]="selectedAssetCurrency" [currencySymbol]="selectedCurrencySymbol" [subheader]="subheaderAssets"></app-assets-donut-chart>
    </div>
    <!-- Big Screen -->
    <div class="col-sm-12 margin-top d-none d-sm-none d-md-block d-lg-block d-xl-block">
      <app-assets-donut-chart *ngIf="isChartVisible" [subheaderY] = "-20" [assetsData]="selectedAssetCurrency" [currencySymbol]="selectedCurrencySymbol" [subheader]="subheaderAssets"></app-assets-donut-chart>
    </div>
  </div>
</div>
</div>
  <div class="spacer-30"></div>
</div>
