<div class="container">
  <!-- section title -->
  <div class="row">
    <h2 class="section-title wow fadeInUp font-open-sans-bold rounded section-header">MOSAIC BENEFICIARIES</h2>
  </div>
  <div class="spacer-60"></div>
  <div class="row margin-left portfolio-wrapper">
    <!-- portfolio item -->
    <div class="col row bg-white shadow-dark padding-30">

      <div class="col-12 col-sm-12 beneficiaries col-lg-4" style="text-align: center; color: black;">
        <p class="font-open-sans-bold">POTCHEFSTROOM</p>
        <div class="row">
          <div class="col-4 col-sm-4">
            <img src="assets/images/CareTakers.png" alt="" />
            <span class="potch-beneficiary">CARETAKERS</span>
            <div class="vertical-potch"></div>
          </div>
          <div class="col-4 col-sm-4">
            <img src="assets/images/Children.png" alt="" />
            <span class="potch-beneficiary">CHILDREN</span>
            <div class="vertical-potch"></div>
          </div>
          <div class="col-4 col-sm-4">
            <img src="assets/images/Families.png" alt="" />
            <span class="potch-beneficiary">FAMILIES</span>
            <div class="vertical-potch"></div>
          </div>
        </div>
        <div class="row font-megaland">
          <div class="col-4 col-sm-4">
            <span class="potch-number" #counterId1>{{counterPotch1}}</span>
          </div>
          <div class="col-4 col-sm-4">
            <span class="potch-number">{{counterPotch2}}</span>
          </div>
          <div class="col-4 col-sm-4">
            <span class="potch-number">{{counterPotch3}}</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 beneficiaries col-lg-4" style="text-align: center; color: #F16725;">
        <p class="font-open-sans-bold">PAARL VALLEY</p>
        <div class="row">
          <div class="col-4 col-sm-4">
            <img src="assets/images/CareTakersOrange.png" alt="" />
            <span class="paarl-beneficiary">CARETAKERS</span>
            <div class="vertical-paarl"></div>
          </div>
          <div class="col-4 col-sm-4">
            <img src="assets/images/ChildrenOrange.png" alt="" />
            <span class="paarl-beneficiary">CHILDREN</span>
            <div class="vertical-paarl"></div>
          </div>
          <div class="col-4 col-sm-4">
            <img src="assets/images/FamiliesOrange.png" alt="" />
            <span class="paarl-beneficiary">FAMILIES</span>
            <div class="vertical-paarl"></div>
          </div>
        </div>

        <div class="row font-megaland">
          <div class="col-4 col-sm-4" style="text-align: center !important">
            <span class="paarl-number" style="text-align: center !important">{{counterPaarl1}}</span>
          </div>
          <div class="col-4 col-sm-4">
            <span class="paarl-number">{{counterPaarl2}}</span>
          </div>
          <div class="col-4 col-sm-4">
            <span class="paarl-number">{{counterPaarl3}}</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 beneficiaries col-lg-4" style="text-align: center">
        <p class="font-open-sans-bold">GQEBERHA</p>
        <div class="row">
          <div class="col-4 col-sm-4">
            <img src="assets/images/CareTakersGrey.png" alt="" />
            <span class="gqeberha-beneficiary">CARETAKERS</span>
            <div class="vertical-gqeberha"></div>
          </div>
          <div class="col-4 col-sm-4">
            <img src="assets/images/ChildrenGrey.png" alt="" />
            <span class="gqeberha-beneficiary">CHILDREN</span>
            <div class="vertical-gqeberha"></div>
          </div>
          <div class="col-4 col-sm-4">
            <img src="assets/images/FamiliesGrey.png" alt="" />
            <span class="gqeberha-beneficiary">FAMILIES</span>
            <div class="vertical-gqeberha"></div>
          </div>
        </div>

        <div class="row font-megaland">
          <div class="col-4 col-sm-4">
            <span class="gqeberha-number">{{counterGqeberha1}}</span>
          </div>
          <div class="col-4 col-sm-4">
            <span class="gqeberha-number">{{counterGqeberha2}}</span>
          </div>
          <div class="col-4 col-sm-4">
            <span class="gqeberha-number">{{counterGqeberha3}}</span>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <p class="intro font-open-sans-semibold">
          The Mosaic model was successfully developed and implemented in South Africa in 2008 and has been replicated in three different provinces, with a site
          in Potchefstroom/Ikageng (North-West Province), Paarl Valley (Western Cape) and Gqeberha (Eastern Cape).
        </p>
      </div>
      <div class="col-sm-6">
        <p class="font-open-sans-bold" style="color: black;">
          CURRENTLY THERE ARE <br /><span class="number font-megaland">{{counterBeneficiaries}}</span><br />
          BENEFICIARIES ENROLLED IN OUR PROGRAMMES, WHICH IS 17% GROWTH FROM 2020.
        </p>
      </div>
    </div>
    <!-- row end -->
  </div>
  <div class="spacer-30"></div>
</div>
