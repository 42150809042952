import { Component, OnInit, Input } from '@angular/core';
import { Chart } from 'angular-highcharts';

@Component({
  selector: 'app-mosaic-statistics-bar-chart',
  templateUrl: './mosaic-statistics-bar-chart.component.html',
  styleUrls: ['./mosaic-statistics-bar-chart.component.css']
})
export class MosaicStatisticsBarChartComponent implements OnInit {
  @Input()
  public chartData: any;

  // public chartData = [
  //   {
  //     name: 'Families',
  //     data: [41, 56]
  //   } as any
  // ];

  public mosaicStatisticBarChart: Chart;
  constructor() {}

  ngOnInit() {
    this.mosaicStatisticBarChart = this.createBarChart();
  }

  public createBarChart(): Chart {
    const chartConfig = new Chart({
      chart: {
        type: 'bar',
        inverted: false,
        height: 250,
        //   width: 700,
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'calibri'
        }
      },
      title: {
        text: this.chartData.header,
        verticalAlign: 'top',
        y: 25,
        style: {
          fontSize: '32px'
        }
      },
      tooltip: {
        useHTML: true,
        headerFormat: '',
        style: {
          fontSize: '20px'
        },
        pointFormat: '{point.y}'
      },
      legend: {
        align: 'center',
        layout: 'horizontal',
        verticalAlign: 'bottom',
        itemStyle: {
          fontSize: '22px'
        },
        backgroundColor: 'transparent'
      },
      credits: {
        enabled: false
      },

      xAxis: {
        categories: [''],
        labels: {
          style: {
            fontSize: '22px'
          }
        },
        title: {
          text: '',
          style: {
            fontSize: '34px',
            color: '#d8d8db'
          }
        }
      },
      yAxis: {
        labels: {
          style: {
            fontSize: '22px'
          }
        },
        title: {
          text: '',
          style: {
            fontSize: '34px',
            color: '#d8d8db'
          }
        }
      },
      exporting: {
        enabled: false
      },
      series: this.chartData.data
    });

    return chartConfig;
  }
}
