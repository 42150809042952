<header *ngIf="this.themeConfig.theme !== 'horizontal'" class="{{ openClass }}" [ngClass]="mobileHeaderClass" (clickOutside)="openClass = ''">
  <div class="container">
    <!-- menu icon -->
    <div class="menu-icon d-inline-flex mr-4">
      <button (click)="openClass = (openClass === 'open') ? '' : 'open'">
        <span></span>
      </button>
    </div>
    <!-- logo image -->
    <div class="site-logo">
      <a routerLink="/">
        <img [src]="this.themeConfig.themeType === 'light' ? 'assets/images/logo-white.png' : 'assets/images/logo-white.png'" alt="Bolby" />
      </a>
    </div>
  </div>
</header>
<!-- Horizontal Menu -->
<header *ngIf="this.themeConfig.theme === 'horizontal'" [ngClass]="desktopHeaderClass" (window:resize)="onResize($event)">
  <div class="container">
    <nav class="navbar navbar-expand-lg" [ngClass]="horizontalNavClass">
      <a class="navbar-brand" routerLink="/">
        <img [src]="desktopLogo" alt="Bolby" />
      </a>
      <button (click)="collapsedCardToggle()" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler" data-target="#navbarNavDropdown" data-toggle="collapse" type="button">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse" id="navbarNavDropdown" [@collapsedCard]="collapsedCard">
        <ul class="navbar-nav ml-auto scrollspy">
          <li class="nav-item" [ngClass]="{'active': currentSection === 'home'}" (click)="scrollTo('home')">
            <a href="javascript:" class="nav-link" [ngClass]="{'active': currentSection === 'home'}">
              <i *ngIf="this.themeConfig.theme !== 'horizontal'" class="icon-home"></i>
              <span *ngIf="this.themeConfig.theme !== 'collapsed'">Home</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{'active': currentSection === 'a-word-from-meyer'}" (click)="scrollTo('a-word-from-meyer')" >
            <a href="javascript:" class="nav-link" [ngClass]="{'active': currentSection === 'a-word-from-meyer'}">
              <i *ngIf="this.themeConfig.theme !== 'horizontal'" class="icon-user-following"></i>
              <span *ngIf="this.themeConfig.theme !== 'collapsed'">Letter From CEO</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{'active': currentSection === 'usa-letter'}" (click)="scrollTo('usa-letter')">
            <a href="javascript:" class="nav-link" [ngClass]="{'active': currentSection === 'usa-letter'}">
              <i *ngIf="this.themeConfig.theme !== 'horizontal'" class="icon-briefcase"></i>
              <span *ngIf="this.themeConfig.theme !== 'collapsed'">Letters From Mosaic</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{'active': currentSection === 'germany-letter'}" (click)="scrollTo('germany-letter')">
            <a href="javascript:" class="nav-link" [ngClass]="{'active': currentSection === 'germany-letter'}">
              <i *ngIf="this.themeConfig.theme !== 'horizontal'" class="icon-graduation"></i>
              <span *ngIf="this.themeConfig.theme !== 'collapsed'">Germany Letter</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{'active': currentSection === 'family-based-orphan-care'}" (click)="scrollTo('family-based-orphan-care')">
            <a href="javascript:" class="nav-link" [ngClass]="{'active': currentSection === 'family-based-orphan-care'}">
              <i *ngIf="this.themeConfig.theme !== 'horizontal'" class="icon-bubbles"></i>
              <span *ngIf="this.themeConfig.theme !== 'collapsed'">Orphan Care</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{'active': currentSection === 'vision-and-growth'}" (click)="scrollTo('vision-and-growth')">
            <a href="javascript:" class="nav-link" [ngClass]="{'active': currentSection === 'vision-and-growth'}">
              <i *ngIf="this.themeConfig.theme !== 'horizontal'" class="icon-bubbles"></i>
              <span *ngIf="this.themeConfig.theme !== 'collapsed'">Vision & Growth</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{'active': currentSection === 'mosaic-model'}" (click)="scrollTo('mosaic-model')">
            <a href="javascript:" class="nav-link" [ngClass]="{'active': currentSection === 'mosaic-model'}">
              <i *ngIf="this.themeConfig.theme !== 'horizontal'" class="icon-layers"></i>
              <span *ngIf="this.themeConfig.theme !== 'collapsed'">Mosaic Model</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{'active': currentSection === 'beneficiaries'}" (click)="scrollTo('beneficiaries')">
            <a href="javascript:" class="nav-link" [ngClass]="{'active': currentSection === 'beneficiaries'}">
              <i *ngIf="this.themeConfig.theme !== 'horizontal'" class="icon-layers"></i>
              <span *ngIf="this.themeConfig.theme !== 'collapsed'">Beneficiaries</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{'active': currentSection === 'measuring-our-impact'}" (click)="scrollTo('measuring-our-impact')">
            <a href="javascript:" class="nav-link" [ngClass]="{'active': currentSection === 'measuring-our-impact'}">
              <i *ngIf="this.themeConfig.theme !== 'horizontal'" class="icon-layers"></i>
              <span *ngIf="this.themeConfig.theme !== 'collapsed'">Measuring Our Impact</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{'active': currentSection === 'mosaic-in-action'}" (click)="scrollTo('mosaic-in-action')">
            <a href="javascript:" class="nav-link" [ngClass]="{'active': currentSection === 'mosaic-in-action'}">
              <i *ngIf="this.themeConfig.theme !== 'horizontal'" class="icon-note"></i>
              <span *ngIf="this.themeConfig.theme !== 'collapsed'">Highlights Of 2021</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{'active': currentSection === 'finance'}" (click)="scrollTo('finance')">
            <a href="javascript:" class="nav-link" [ngClass]="{'active': currentSection === 'finance'}">
              <i *ngIf="this.themeConfig.theme !== 'horizontal'" class="icon-bubbles"></i>
              <span *ngIf="this.themeConfig.theme !== 'collapsed'">Finance</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</header>

<!-- Vertical Menu -->
<header *ngIf="this.themeConfig.theme !== 'horizontal'" class="d-flex align-items-start flex-column {{ openClass }}" [ngClass]="desktopHeaderClass">

  <!-- logo image -->
  <div class="site-logo">
    <a routerLink="/">
      <img [src]="desktopLogo" alt="Bolby" />
    </a>
  </div>

  <!-- main menu -->
  <nav>
    <ul class="vertical-menu scrollspy">
      <li [ngClass]="{'active': currentSection === 'home'}" (click)="scrollTo('home')">
        <a href="javascript:" class="nav-link" [ngClass]="{'active': currentSection === 'home'}"><i class="icon-home"></i>
          <span *ngIf="this.themeConfig.theme !== 'collapsed'">Home</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentSection === 'a-word-from-meyer'}" (click)="scrollTo('a-word-from-meyer')" >
        <a href="javascript:" class="nav-link" [ngClass]="{'active': currentSection === 'a-word-from-meyer'}"><i class="icon-speech"></i>
          <span *ngIf="this.themeConfig.theme !== 'collapsed'">Letter From CEO</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentSection === 'usa-letter'}" (click)="scrollTo('usa-letter')">
        <a href="javascript:" class="nav-link" [ngClass]="{'active': currentSection === 'usa-letter'}"><i class="icon-envelope-letter"></i>
          <span *ngIf="this.themeConfig.theme !== 'collapsed'">USA Letter</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentSection === 'germany-letter'}" (click)="scrollTo('germany-letter')">
        <a href="javascript:" class="nav-link" [ngClass]="{'active': currentSection === 'germany-letter'}"><i class="icon-envelope-letter"></i>
          <span *ngIf="this.themeConfig.theme !== 'collapsed'">Germany Letter</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentSection === 'family-based-orphan-care'}" (click)="scrollTo('family-based-orphan-care')">
        <a href="javascript:" class="nav-link" [ngClass]="{'active': currentSection === 'family-based-orphan-care'}"><i class="icon-people"></i>
          <span *ngIf="this.themeConfig.theme !== 'collapsed'">Orphan Care</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentSection === 'vision-and-growth'}" (click)="scrollTo('vision-and-growth')">
        <a href="javascript:" class="nav-link" [ngClass]="{'active': currentSection === 'vision-and-growth'}"><i class="icon-graph"></i>
          <span *ngIf="this.themeConfig.theme !== 'collapsed'">Vision & Growth</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentSection === 'mosaic-model'}" (click)="scrollTo('mosaic-model')">
        <a href="javascript:" class="nav-link" [ngClass]="{'active': currentSection === 'mosaic-model'}"><i class="icon-organization"></i>
          <span *ngIf="this.themeConfig.theme !== 'collapsed'">Mosaic Model</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentSection === 'beneficiaries'}" (click)="scrollTo('beneficiaries')">
        <a href="javascript:" class="nav-link" [ngClass]="{'active': currentSection === 'beneficiaries'}"><i class="icon-layers"></i>
          <span *ngIf="this.themeConfig.theme !== 'collapsed'">Beneficiaries</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentSection === 'measuring-our-impact'}" (click)="scrollTo('measuring-our-impact')">
        <a href="javascript:" class="nav-link" [ngClass]="{'active': currentSection === 'measuring-our-impact'}"><i class="icon-reload"></i>
          <span *ngIf="this.themeConfig.theme !== 'collapsed'">Our Impact</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentSection === 'mosaic-in-action'}" (click)="scrollTo('mosaic-in-action')">
        <a href="javascript:" class="nav-link" [ngClass]="{'active': currentSection === 'mosaic-in-action'}"><i class="icon-heart"></i>
          <span *ngIf="this.themeConfig.theme !== 'collapsed'">Highlights Of 2021</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentSection === 'finance'}" (click)="scrollTo('finance')">
        <a href="javascript:" class="nav-link" [ngClass]="{'active': currentSection === 'finance'}"><i class="icon-credit-card"></i>
          <span *ngIf="this.themeConfig.theme !== 'collapsed'">Finance</span>
        </a>
      </li>
      <li [ngClass]="{'active': currentSection === 'locations'}" (click)="scrollTo('locations')">
        <a href="javascript:" class="nav-link" [ngClass]="{'active': currentSection === 'locations'}"><i class="icon-directions"></i>
          <span *ngIf="this.themeConfig.theme !== 'collapsed'">Locations</span>
        </a>
      </li>
    </ul>
  </nav>

  <!-- site footer -->
  <div class="footer">
    <!-- copyright text -->
    <span class="copyright">© 2021 Mosaic.</span>
  </div>

</header>
<!-- main layout -->
<main class="{{ openClass }}" [ngClass]="contentClass">
  <router-outlet></router-outlet>

  <!-- Go to top button -->
  <span><a class="floating-button" id="return-to-top" (click)="scrollUp()"><i class="fas fa-arrow-up"></i></a></span>
  <span><a class="floating-button" id="return-to-bottom" (click)="scrollDown()"><i class="fas fa-arrow-down"></i></a></span>
</main>
