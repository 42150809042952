import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vision-and-growth',
  templateUrl: './vision-and-growth.component.html',
  styleUrls: ['./vision-and-growth.component.scss']
})
export class VisionAndGrowthComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
