import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScrollSpyDirective } from '../scroll-spy/scroll-spy.directive';
import { SectionsModule } from '../sections/sections.module';
import { MosaicDataService } from './services';


@NgModule({
  declarations: [
    ScrollSpyDirective,
  ],
  imports: [
    CommonModule,
    SectionsModule,
  ],
  exports: [
    ScrollSpyDirective,
    SectionsModule
  ],
  providers: [ScrollSpyDirective, MosaicDataService],
})
export class SharedModule { }
