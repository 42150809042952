import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LightboxModule } from 'ngx-lightbox';
import { NgImageSliderModule } from 'ng-image-slider';
import {IvyCarouselModule} from 'angular-responsive-carousel';

import { HomeComponent } from './home/home.component';
import { AWordFromMeyerComponent } from './a-word-from-meyer/a-word-from-meyer.component';
import { USALetterComponent } from './usa-letter/usa-letter.component';
import { GermanyLetterComponent } from './germany-letter/germany-letter.component';
import { MosaicModelComponent } from './mosaic-model/mosaic-model.component';
import { FinanceComponent } from './finance/finance.component';
import { FamilyBasedOrphanCareComponent } from './family-based-orphan-care/family-based-orphan-care.component';

import { MosaicInActionComponent } from './mosaic-in-action/mosaic-in-action.component';
import { UiModalComponent } from './ui-modal/ui-modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BarChartsComponent } from './charts/bar-charts/bar-charts.component';
import { MosaicStatisticsBarChartComponent } from './charts/mosaic-statistics-bar-chart/mosaic-statistics-bar-chart.component';
import { ChartModule } from 'angular-highcharts';
import { VisionAndGrowthComponent } from './vision-and-growth/vision-and-growth.component';
import { BeneficiariesComponent } from './beneficiaries/beneficiaries.component';
import { VisionQuoteComponent } from './vision-quote/vision-quote.component';
import { MeasuringOurImpactComponent } from './measuring-our-impact/measuring-our-impact.component';
import { VolunteeringQuoteComponent } from './volunteering-quote/volunteering-quote.component';
import { LocationsComponent } from './locations/locations.component';
import { AssetsDonutChartComponent } from './charts/assets-donut-chart/assets-donut-chart';
import { OpexDonutChartComponent } from './charts/opex-donut-chart/opex-donut-chart';
import { CapexDonutChartComponent } from './charts/capex-donut-chart/capex-donut-chart';
@NgModule({
  declarations: [
    HomeComponent,
    VisionQuoteComponent,
    AWordFromMeyerComponent,
    USALetterComponent,
    GermanyLetterComponent,
    MosaicModelComponent,
    FinanceComponent,
    FamilyBasedOrphanCareComponent,
    MosaicInActionComponent,
    UiModalComponent,
    BarChartsComponent,
    MosaicStatisticsBarChartComponent,
    VisionAndGrowthComponent,
    BeneficiariesComponent,
    MeasuringOurImpactComponent,
    VolunteeringQuoteComponent,
    LocationsComponent,
    AssetsDonutChartComponent,
    OpexDonutChartComponent,
    CapexDonutChartComponent
  ],
  exports: [
    HomeComponent,
    VisionQuoteComponent,
    AWordFromMeyerComponent,
    USALetterComponent,
    GermanyLetterComponent,
    MosaicModelComponent,
    FinanceComponent,
    FamilyBasedOrphanCareComponent,
    VisionAndGrowthComponent,
    MosaicInActionComponent,
    BeneficiariesComponent,
    MeasuringOurImpactComponent,
    VolunteeringQuoteComponent,
    LocationsComponent,
    NgbModule
  ],
  imports: [
    CommonModule,
    LightboxModule,
    NgImageSliderModule,
    IvyCarouselModule,
    NgbModule,
    ChartModule,
  ]
})
export class SectionsModule { }
