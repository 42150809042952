<div class="container">
  <!-- section title -->
  <div class="row">
    <h2 class="section-title wow fadeInUp rounded section-header"><span class="font-open-sans-light">LETTER</span><span class="font-open-sans-bold"> FROM MOSAIC GERMANY</span></h2>
  </div>
  <span class="sub-header font-quentin rounded">Frieda Hoffman</span>
  <div class="spacer-60"></div>

  <div class="row">
    <div class="col-md-3">
      <div class="text-center text-md-left">
        <!-- avatar image -->
        <img src="assets/images/Frieda 1.jpg" alt="Frieda" />
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-9 triangle-left-md triangle-top-sm">
      <div class="rounded bg-white shadow-dark padding-30">
        <div class="row">
          <div>
            <!-- Germany letter text -->
            <div class="font-open-sans-regular" style="color: black;">
              <p>
                Germany’s involvement with Mosaic Community Developments has grown in the last years to such an extent, that the decision was taken in 2020 to
                found a registered NGO, in order to support the work of Mosaic in a more consistent and congruent way
              </p>
              <p>
                2021 has therefore been quite an exciting year. It included many opportunities - and many challenges at the same time: from the birthing of our
                newly founded NGO MOSAIC SA Deutschland e.V. in January, the launching of our website and social media sites in March, the launching of the
                German version of the Sponsor-A-Child program up to our fundraising events at the end of the year.
              </p>
              <p>
                One of the many highlights of the year was the launching of the Sponsor-A-Child-program. For many of us maybe a sponsorship program, that
                deviates from the normal sponsorship program, but again it underlines the fact that what Mosaic does is not mainstream, but a well thought
                through process, that will enhance the quality of life of their children.
              </p>
              <p>We appreciate Mosaic Community Developments striving for excellence for their children.</p>
              <p>
                We appreciate the way they constantly keep on evaluating and reevaluating their goals, systems and procedures – the wellbeing and the of the
                children and families of Mosaic always being the focus of what they do.
              </p>
              <p>
                We see ourselves as a group of compassionate people from all walks of life, but all of us with the same goal: Making a small contribution now
                towards a better future for many orphans and vulnerable children in South Africa and helping them to reach their Godgiven potential.
              </p>
              <p>
                To us it is really a privilege to work together with Mosaic in South Africa an its partnering organizations in the USA, South Africa and Sweden
                to pursue this goal.
              </p>
              <p>
                For us in Germany it is clear that in order to impact the lives of as many orphans as possible, it takes a global family to hold hands and
                support the efforts of Mosaic.
              </p>
              <p>
                Only through meaningful dialogue, support and through the continuous measurement of the impact we have, we can change lives over the long term.
              </p>
              <p>We appreciate Mosaic Community Developments striving for excellence for their children.</p>
              <p>
                That is the reason why, in spite of the effects of Cororona an other challenges that 2022 might bring, we look forward to a year blessed with
                many new opportunities!
              </p>
              <p class="quote-author">Daria Kholod said:</p>
            </div>

            <i class="font-open-sans-italic quote"
              >“Making an impact critically involves stopping ignoring our world’s problems and making your (even small) contribution towards a better future.
              It is to join the movement of like-minded, compassionate people who realize that we have to act now.”</i
            >
          </div>
        </div>
      </div>
    </div>
    <!-- row end -->
  </div>
  <div class="spacer-30"></div>
</div>
