import { Component, ElementRef, HostListener, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-beneficiaries',
  templateUrl: './beneficiaries.component.html',
  styleUrls: ['./beneficiaries.component.scss'],
})
export class BeneficiariesComponent implements OnInit, OnDestroy {
  public counterPotch1 = 0;
  public counterPotch2 = 0;
  public counterPotch3 = 0;
  public counterPaarl1 = 0;
  public counterPaarl2 = 0;
  public counterPaarl3 = 0;
  public counterGqeberha1 = 0;
  public counterGqeberha2 = 0;
  public counterGqeberha3 = 0;
  public counterBeneficiaries = 0;
  public heightEl = 0;
  public counterEl: any;
  public showValues = true;
  constructor() {}

  @ViewChild('counterId1') set playerRef(ref: ElementRef<any>) {
    this.counterEl = ref.nativeElement;
  }

  scroll = (event: any): void => {
    // const counter1 = document.getElementById('counterId1');
    // const id = event.srcElement.id;
    if (this.isInViewport(this.counterEl)) {
    if (this.showValues) {
      // Increment Potch counter 1
      const incrementTimer1 = timer(50, 50);
       incrementTimer1.subscribe((val) => {
        if (this.counterPotch1 > 40) {
          return;
        }
        this.counterPotch1 ++;
      });

      // Increment Potch counter 2
      const incrementTimer2 = timer(50, 50);
       incrementTimer2.subscribe((val) => {
        if (this.counterPotch2 > 94) {
          return;
        }
        this.counterPotch2 ++;
      });

      // Increment Potch counter 3
      const incrementTimer3 = timer(50, 50);
       incrementTimer3.subscribe((val) => {
        if (this.counterPotch3 > 25) {
          return;
        }
        this.counterPotch3 ++;
      });

      // Increment Paarl counter 1
      const incrementTimer4 = timer(50, 50);
       incrementTimer4.subscribe((val) => {
        if (this.counterPaarl1 > 33) {
          return;
        }
        this.counterPaarl1 ++;
      });

      // Increment Paarl counter 2
      const incrementTimer5 = timer(50, 50);
       incrementTimer5.subscribe((val) => {
        if (this.counterPaarl2 > 90) {
          return;
        }
        this.counterPaarl2 ++;
      });

      // Increment Paarl counter 3
      const incrementTimer6 = timer(50, 50);
       incrementTimer6.subscribe((val) => {
        if (this.counterPaarl3 > 27) {
          return;
        }
        this.counterPaarl3 ++;
      });

      // Increment Gqeberha counter 1
      const incrementTimer7 = timer(50, 50);
       incrementTimer7.subscribe((val) => {
        if (this.counterGqeberha1 > 37) {
          return;
        }
        this.counterGqeberha1 ++;
      });

      // Increment Gqeberha counter 2
      const incrementTimer8 = timer(50, 50);
       incrementTimer8.subscribe((val) => {
        if (this.counterGqeberha2 > 103) {
          return;
        }
        this.counterGqeberha2 ++;
      });

      // Increment Gqeberha counter 3
      const incrementTimer9 = timer(50, 50);
      incrementTimer9.subscribe((val) => {
        if (this.counterGqeberha3 > 31) {
          return;
        }
        this.counterGqeberha3 ++;
      });

      // Increment Benefeciaries counter
      const incrementTimer10 = timer(50, 13);
      incrementTimer10.subscribe((val) => {
        if (this.counterBeneficiaries > 402) {
          return;
        }
        this.counterBeneficiaries ++;
      });
      this.showValues = false;
    }
  }
  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scroll, true);
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }

  public isInViewport(element): boolean {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
}
