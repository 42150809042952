import { Component, OnInit, Input } from '@angular/core';
import { Chart } from 'angular-highcharts';

@Component({
  selector: 'app-assets-donut-chart',
  templateUrl: './assets-donut-chart.html',
  styleUrls: ['./assets-donut-chart.scss']
})
export class AssetsDonutChartComponent implements OnInit {
  @Input()
  public subheaderY: any;

  @Input()
  public assetsData: any;

  @Input()
  public currencySymbol: string;

  @Input()
  public subheader: string;

  public on2ndLevel = false;

  public chartHeight = 600;

  public series = [
    {
      type: 'pie',
      name: 'Departments',
      innerSize: '50%',
      cursor: 'pointer',
      data: []
    } as any
  ];

  public semiCircleDonutChart: Chart;

  constructor() {}

  ngOnInit() {
    this.setDefaultData();
  }

  public loadChart() {
    this.semiCircleDonutChart = this.createSemiCircleDonutChart();
  }

  public setDefaultData() {
    this.series[0].data = this.assetsData;
    this.chartHeight = 755;
    this.on2ndLevel = false;
    this.loadChart();
  }

  public createSemiCircleDonutChart(): Chart {
    const chartConfig = new Chart({
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
        height: this.chartHeight,
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'calibri'
        }
      },
      legend: {
        align: 'center',
        layout: 'horizontal',
        verticalAlign: 'bottom',
        itemStyle: {
          fontSize: '22px',
          color: '#17171a',
        },
        backgroundColor: 'transparent'
      },
      credits: {
        enabled: false
      },
      title: {
        text: 'ASSETS',
        verticalAlign: 'top',
        y: 25,
        style: {
          fontSize: '38px',
          color: '#17171a',
        }
      },
      subtitle: {
        text: this.subheader,
        verticalAlign: 'middle',
        y: this.subheaderY,
        style: {
          fontSize: '30px',
          color: '#17171a',
        }
      },
      tooltip: {
        headerFormat: '',
        useHTML: true,
        style: {
          fontSize: '23px'
        },
        pointFormat:
        `<div> {point.name} </div> <div>{point.percentage:.1f}% </div> <div> ${ this.currencySymbol } {point.y} </div>  `
      },
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            enabled: false
          },
          customButton: {
            enabled: this.on2ndLevel,
            text: 'Show Departments',
            onclick: () => this.setDefaultData(),
            // theme: {
            //   r: 10,
            //   'font-size': '24px',
            //   fill: 'white'
            // }
          }
        }
      },
      plotOptions: {
        pie: {
          showInLegend: true,
          dataLabels: {
            enabled: false, // !this.on2ndLevel,
            distance: -90,
            style: {
              // fontWeight: 'bold',
              color: '#d8d8db',
              fontSize: '14px',
              fontFamily: 'sans-serif',
              textOutline: '2px 1px 0 #000',
            },
            format: '{point.name} <br> <b>{point.percentage:.1f}% </b> <br> '
          },
          startAngle: -90,
          endAngle: 360,
          center: ['50%', '50%'],
          size: '100%'
        }
      },
      series: this.series
    });
    return chartConfig;
  }
}
