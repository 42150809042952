import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';



@Component({
  selector: 'app-measuring-our-impact',
  templateUrl: './measuring-our-impact.component.html',
  styleUrls: ['./measuring-our-impact.component.scss'],
  animations: [
    trigger('inOut', [
      transition(':enter',
      [
        style({  opacity: 0, transform: 'translateX(200px)'}),           // initial styles
        animate('700ms ease-out',
          style({  opacity: 1, transform: 'translateX(0%)' })          // final style after the transition has finished
        )
      ], ),
      transition('* => void', [
        animate('500ms',
          style({ opacity: 0 })          // we asume the initial style will be always opacity: 1
        )
      ])
    ])
  ],
})
export class MeasuringOurImpactComponent implements OnInit {
  // readmore variable, its true than read more string will print
  readMoreFoodAndNutrition = true;
  readMoreShelterAndCare = true;
  readMoreProtection = true;
  readMoreHealth = true;
  readMorePsychosocial = true;
  readMoreEducationAndSkills = true;
  readMoreEconomic = true;
  readMoreSpiritualAndCommunity = true;

  // hiding info box
  visibleFoodAndNutrition = false;
  visibleShelterAndCare = false;
  visibleProtection = false;
  visibleHealth = false;
  visiblePsychosocial = false;
  visibleEducationAndSkills = false;
  visibleEconomic = false;
  visibleSpiritualAndCommunity = false;
  constructor() { }

  ngOnInit(): void {
  }

  // onclick toggling both
  onClick(domain) {
    switch (domain) {
      case "foodAndNutrition":
        this.readMoreFoodAndNutrition = !this.readMoreFoodAndNutrition; // not equal to condition
        this.visibleFoodAndNutrition = !this.visibleFoodAndNutrition;
        break;
      case "shelterAndCare":
        this.readMoreShelterAndCare = !this.readMoreShelterAndCare;
        this.visibleShelterAndCare = !this.visibleShelterAndCare;
        break;
      case "protection":
        this.readMoreProtection = !this.readMoreProtection;
        this.visibleProtection = !this.visibleProtection;
        break;
      case "health":
        this.readMoreHealth = !this.readMoreHealth;
        this.visibleHealth = !this.visibleHealth;
        break;
      case "psychosocial":
        this.readMorePsychosocial = !this.readMorePsychosocial;
        this.visiblePsychosocial = !this.visiblePsychosocial;
        break;
      case "educationAndSkills":
        this.readMoreEducationAndSkills = !this.readMoreEducationAndSkills;
        this.visibleEducationAndSkills = !this.visibleEducationAndSkills;
        break;
      case "economic":
        this.readMoreEconomic = !this.readMoreEconomic;
        this.visibleEconomic = !this.visibleEconomic;
        break;
      case "spiritualAndCommunity":
        this.readMoreSpiritualAndCommunity = !this.readMoreSpiritualAndCommunity;
        this.visibleSpiritualAndCommunity = !this.visibleSpiritualAndCommunity;
        break;
    }
  }

  scrollTo(section) {
    const sectionHtml = document.querySelector('#' + section);
    if (sectionHtml !== null) {
      sectionHtml.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }
}
