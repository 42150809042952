<div class="container">
  <!-- section title -->
  <div class="row">
    <h2 class="section-title wow fadeInUp section-header rounded"><span class="font-open-sans-light">LETTER</span><span class="font-open-sans-bold"> FROM MOSAIC USA</span></h2>
  </div>

  <div >
    <span class="rounded sub-header font-quentin">Karen Baker</span>
  </div>

  <div class="spacer-60"></div>

  <div class="row">
    <div class="col-md-3">
      <div class="text-center text-md-left">
        <!-- avatar image -->
        <img src="assets/images/Karen Baker 1.jpg" alt="Karen" />
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-9 triangle-left-md triangle-top-sm">
      <div class="rounded bg-white shadow-dark padding-30">
        <div class="row">
          <div>
            <!-- USA letter text -->
            <p><span class="font-open-sans-bold" style="color: rgba(0, 0, 0, 0.808)">MOSAIC IS SO MUCH MORE</span></p>
            <div class="font-open-sans-light" style="color: black">
              <p>
                When I first became involved with Mosaic, the focus was to reach out to orphans in South Africa and find the way to help them live a better
                life. I realize now that, in the beginning, the definition of a “better” life was from an outsider’s point of view.
              </p>

              <p>
                Over the years, Mosaic has become so much more. Our vision grew to support not just orphans, but all vulnerable children in South Africa.
                Through the years we came to realize that in order to truly help our OVC to realize their God-given potential, we needed to listen carefully to
                find out what their needs truly were.
              </p>

              <p>
                This past year, when it would have been so easy to focus on the problems that COVID presented to this world, the staff at Mosaic took the
                opportunity to listen and to learn. Mosaic does so much. But it is not just building a house or providing an afternoon of safety after school
                that will change the life for a child.
              </p>

              <p>
                It is embracing the family and helping them deal with all of the challenges that life has presented to all of them. We have to include the
                family that has already made a commitment to the child in our planning and our efforts if we truly hope to allow a child to reach their
                potential.
              </p>

              <p>
                Whether through job creation for parents or facilitating parent support group and offering individual counseling sessions for any member of the
                Mosaic family; whether making sure there are safe, entertaining and educational afterschool programs and talent development for our children;
                whether it is providing the opportunity for those who desire further education to obtain that goal; whether it is celebrating special days in
                life together as a family; whether it is providing a home or making sure there is food to eat, Mosaic has evolved to become an organization
                willing to learn and to grow with our families.
              </p>

              <p>
                But without your support, the energy and wisdom of Mosaic could only go so far. It is because you believe in what we are doing; because you are
                willing to support the dedication and work being done that, together, we are able to walk with our families to make their journey easier.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- row end -->
  </div>
  <div class="spacer-30"></div>
</div>
