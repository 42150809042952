<div class="container">
  <!-- section title -->
  <div class="row">
    <span class="quote font-open-sans-bold">OUR VISION IS FOR EVERY ORPHANED AND VULNERABLE CHILD IN SOUTH AFRICA TO HAVE A LOVING AND CARING FAMILY, WHERE THEY CAN BE EQUIPPED TO REALISE THEIR GOD - GIVEN POTENTIAL.</span>
  </div>
  <div class="spacer-60"></div>

  <div class="row blog-wrapper"></div>
  <div class="spacer-30"></div>
</div>
