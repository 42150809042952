import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {IAlbum, IEvent, Lightbox, LIGHTBOX_EVENT, LightboxConfig, LightboxEvent} from 'ngx-lightbox';
import { Subscription } from 'rxjs';

interface IMosaicInAction {
  title: string;
  isArrangement: boolean;
  parphs: string[];
  cityText: string;
  imgPath?: string;
  videoUrl?: string;
  quote?: string;
}

@Component({
  selector: 'app-mosaic-in-action',
  templateUrl: './mosaic-in-action.component.html',
  styleUrls: ['./mosaic-in-action.component.scss']
})
export class MosaicInActionComponent implements OnInit {
  @Input() albums: Array<IAlbum>;
  public subscription: Subscription;

  constructor(private lightbox: Lightbox, private lightboxEvent: LightboxEvent, private lighboxConfig: LightboxConfig, private sanitizer: DomSanitizer) {
    this.albums = this.albums ? this.albums : [];
    lighboxConfig.fadeDuration = 1;
  }

  public mosaicInActions = [
    {
      title: 'Child Protection Awareness Week',
      isArrangement: true,
      parphs: ['The children at Mosaic made posters for Child Protection Week. They also had the opportunity to deliver speeches and even acted out plays to raise awareness of children’s rights. '],
      cityText: 'POTCHEFSTROOM',
      imgPath: 'assets/images/child protection.jpeg'
    } as IMosaicInAction,
    {
      title: 'Sustainability With African Grower Gardens',
      isArrangement: false,
      parphs: ['We piloted a vegetable garden project at our centre in Paarl Valley in order to nourish our communities and educate them about the importance of healthy eating habits and sustainability.', 'We are extremely excited about the cultivation of our own veggies in this garden, as we know that a healthy diet has a direct influence on child’s concentration and ability to function optimally.'],
      cityText: 'PAARL VALLEY CENTRE',
      imgPath: 'assets/images/Gardens.PNG'
    } as IMosaicInAction,
    {
      title: 'New Initiative Father & Son Morning',
      isArrangement: true,
      parphs: ['A new initiative was launched in 2021 – Father and Son Morning. Our kids do not have many father figures and male rolemodels. This event created the opportunity for our kids and their dads to connect and build better relationships.'],
      cityText: 'POTCHEFSTROOM',
      imgPath: 'assets/images/father and son 1.jpeg'
    } as IMosaicInAction,
    {
      title: 'Bursaries For Two Of Our Learners',
      isArrangement: false,
      parphs: ['Two of our high school learners got bursaries to attend University of Cape Town’s Online School. This opportunity gives them access to a high standard of education, creating a springboard for their futures.', 'It’s important to us that our children attend school and obtaining life skills. This is why we have a wide range of after school programmes and talent development opportunities for them. From reading programmes, arts and crafts to sports, we make sure that they engage in age-appropriate productive activities'],
      cityText: 'PAARL VALLEY CENTRE',
      imgPath: 'assets/images/UCT.png'
    } as IMosaicInAction,
    {
      title: 'Rebuilding A Home For A Family In Need',
      isArrangement: true,
      parphs: ['Depending on their specific situation, Mosaic will support a family with shelter and care in the following ways: building a house for the family, give assistance regarding RDP-housing (government housing) or assist with renovation of a current house.', 'We have incredible moms as part of the Mosaic Community, working very hard to care for their foster children. In June 2021, a Mosaic family’s home, with one caretaker and 6 children, was badly damaged in a fire in Gqeberha (PE).', 'A shack, which was attached to the house, was completely destroyed by the fire. With grateful hearts we were able to raise enough funds to rebuild and renovate the Bhabathane house.'],
      cityText: 'GQEBERHA (PE)',
      imgPath: 'assets/images/Rebuilding.jpg'
    } as IMosaicInAction,
    {
      title: 'Life Coaching For Our Mosaic Moms',
      isArrangement: false,
      cityText: 'PAARL VALLEY CENTRE',
      parphs: ['We offer life coaching to our Mosaic Moms. Bernadette Arendse, who cares for 4 children in the Paarl Valley, says that she benefits from these sessions.  “It helps me to talk to the children about feelings. The life coaching helps us to bond better as a family,” she shared.'],
      videoUrl: this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/foEeN9yY7dY?modestbranding=1')
    } as IMosaicInAction,
    {
      title: 'Food Parcels For Our Families In Need',
      isArrangement: true,
      parphs: ['As the global pandemic still continued last year, we continued to provide food support where needed. Although 2021 saw South Africa in a ‘lighter’ lockdown, a lot of restrictions still impacted caregivers’ ability to work and to provide for their families', 'We wanted to make sure that our families had food on the table. We provided food parcels to our families in need. One of our foster moms in Gqberha (PE), Nomathamsonqu Nkosikulu, who looks after four children said that:'],
      cityText: 'GQEBERHA (PE)',
      quote: 'In a ‘don’t know’ moment, God can do a miracle.',
      videoUrl: this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/gjph92JoFlQ?modestbranding=1')
    } as IMosaicInAction,
  ];

  ngOnInit(): void {}
}
