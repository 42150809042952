import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-usa-letter',
  templateUrl: './usa-letter.component.html',
  styleUrls: ['./usa-letter.component.scss']
})
export class USALetterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  scrollTo(section) {
    const sectionHtml = document.querySelector('#' + section);
    if (sectionHtml !== null) {
      sectionHtml.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }
  }

}
