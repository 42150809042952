import {Component, Input, OnInit} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-family-based-orphan-care',
  templateUrl: './family-based-orphan-care.component.html',
  styleUrls: ['./family-based-orphan-care.component.scss']
})
export class FamilyBasedOrphanCareComponent implements OnInit {
  @Input() themeType: string;
  public workFilter: string;
  public singleGallery1: any;
  public albums: any = [];
  public videoUrl: any;

  // videoObject: Array<object> = [
  //   {
  //     video: this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/watch?v=qf9z4ulfmYw'),
  //     posterImage: 'assets/images/works/3.svg',
  //   }
  // ];

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/W1J6sYBMCLw');
  }

  // onChange (e) {
  //   this.workFilter = e.target.value;
  // }
}
