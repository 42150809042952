<div class="container">
  <!-- section title -->
  <div class="row">
    <h2 class="section-title wow fadeInUp font-open-sans-bold section-header rounded">MOSAIC MODEL</h2>
  </div>

  <div class="spacer-60"></div>
  <div class="row margin-left portfolio-wrapper">
    <div class="col row bg-box shadow-dark white-text padding-30">
      <span class="font-open-sans-regular"
        >Our model has a holistic focus on the OVC and their caregivers. We want to create mechanisms to intervene on an educational, emotional and physical
        level, to ensure Mosaic families and the OVC in their care are healthy on various different levels.</span>
    </div>
  </div>
  <div class="spacer-30"></div>

  <!-- Identifying a family -->
  <div class="row row-no-margins">
    <div class="col-md-4 model-item-margins">
      <div class="portfolio-item rounded text-center text-light shadow-identifying bg-identifying padding-30">
        <div class="details">
          <h4 class="font-open-sans-light title">We identify a family already taking care of two or more orphaned and vulnerable children.</h4>
        </div>
        <div class="thumb">
          <img class="img-size" src="assets/images/Identifying a family.png" alt="Magnifying Glass." />
          <h3 class="mb-3 mt-0 model-font-size">1. Identifying a family</h3>
          <div class="mask-identify-family"></div>
        </div>
      </div>
    </div>

    <!-- Growing parents -->
    <div class="col-md-4 model-item-margins">
      <div class="portfolio-item rounded text-center text-light shadow-growing-parents bg-growing-parents padding-30">
        <div class="details">
          <h4 class="font-open-sans-light title">
            We make sure that parents taking care of orphaned and vulnerable children are adequately equipped to do so, by investing extensively in their growth
            and development. <br>  <a href="https://www.youtube.com/watch?v=n2yV8lz9NhU"><i class="icon-social-youtube"> </i> View More</a>
          </h4>
        </div>
        <div class="thumb">
          <img class="img-size" src="assets/images/Growing Parents.png" alt="Family" />
          <h3 class="mb-3 mt-0 model-font-size">2. Growing parents</h3>
          <div class="mask-identify-family"></div>
        </div>
      </div>
    </div>

    <!-- Creating jobs -->
    <div class="col-md-4 model-item-margins">
      <div class="portfolio-item rounded text-center text-light shadow-creating bg-creating padding-30">
        <div class="details">
          <h4 class="font-open-sans-light title">
            We create jobs for parents taking care of orphaned and vulnerable children, to make sure there is enough money to put food and other necessities on
            the table.
          </h4>
        </div>
        <div class="thumb">
          <img class="img-size" src="assets/images/Creating Jobs.png" alt="Jobs" height="5" />
          <h3 class="mb-3 mt-0 model-font-size">3. Creating jobs</h3>
          <div class="mask-identify-family"></div>
        </div>
      </div>
    </div>
  </div>

  <!-- Growing children -->
  <div class="row row-no-margins">
    <div class="col-md-2"></div>

    <div class="col-md-4 model-item-margins">
      <div class="portfolio-item rounded text-center text-light shadow-growing-children bg-growing-children padding-30">
        <div class="details">
          <h4 class="font-open-sans-light title">
            We create better futures for orphaned and vulnerable children through providing access to good quality schools; after-school programs; talent
            development programs; emotional and spiritual care programs and a college education.
            <br>  <a href="https://www.youtube.com/watch?v=d3WOluCQENM"><i class="icon-social-youtube"> </i> View More</a></h4>
        </div>
        <div class="thumb">
          <img class="img-size" src="assets/images/Growing Children.png" alt="Growing Children" />
          <h3 class="mb-3 mt-0 model-font-size">4. Growing children</h3>
          <div class="mask-identify-family"></div>
        </div>
      </div>
    </div>

    <!-- Continued support -->
    <div class="col-md-4 model-item-margins">
      <div class="portfolio-item rounded text-center text-light shadow-continued-support bg-continued-support padding-30">
        <div class="details">
          <h4 class="font-open-sans-light title">
            We continue to support our families by facilitating access to government services, facilitating parent support groups, conducting individual
            counselling sessions, conducting Bible study groups and organising community church meetings. As part of the support we provide, we may also build a
            3-bedroom brick house for our families.
          </h4>
        </div>
        <div class="thumb">
          <img class="img-size" src="assets/images/Continued support of family.png" alt="Heart" />
          <h3 class="mb-3 mt-0 model-font-size">5. Continued support</h3>
          <div class="mask-identify-family"></div>
        </div>
      </div>
    </div>

    <div class="col-md-2"></div>
  </div>
  <div class="spacer-30"></div>
</div>
