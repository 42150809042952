<div class="container">
  <!-- section title -->
  <div class="row">
    <h2 class="section-title wow fadeInUp rounded section-header"><span class="font-open-sans-light">LETTER</span><span class="font-open-sans-bold"> FROM CEO</span></h2>
  </div>

  <span class="sub-header font-quentin rounded">Meyer Conradie</span>
  <div class="spacer-60"></div>

  <div class="row">
    <div class="col-md-3">
      <div class="text-center text-md-left">
        <!-- avatar image -->
        <img src="assets/images/Meyer Conradie.jpg" alt="Meyer" />
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-9 triangle-left-md triangle-top-sm">
      <div class="rounded bg-white shadow-dark padding-30">
        <div class="row">
          <div>
            <!-- CEO letter text  -->
            <p class="font-open-sans-light-italic" style="color: #f16725">
              This past year was tough, but we persevered! Not because of the work of any one person, but rather as a result of God’s grace and an amazing team
              of passionate people who worked really hard to raise funds, facilitate programmes, clean centres, cook food, drive vehicles and just never giving
              up to serve our children well! If you are reading this report, you are probably part of this amazing team.
            </p>
            <p class="font-quentin" style="color: #f16725; font-size: 30px">Thank you!</p>
            <div style="color: black">
              <p class="font-open-sans-light">
                With starting the first independent Mosaic Centre and launching OVCmeasure software this past year, we are officially in a new season of growth
                for Mosaic. A season where we will continue to see more children joining our existing programmes, but we will also see more and more children
                being impacted by other organisations who implement the Mosaic Model and use the OVCmeasure software.
              </p>

              <p class="font-open-sans-semibold-italic">So, how did this new season come about?</p>
              <div class="font-open-sans-light">
                <p>Since the start of Mosaic, we’ve always had big dreams and good intentions to impact and change the lives of the children we work with.</p>
                <p>
                  I believe this is also true for many other orphaned and vulnerable child care organisations around the world. Unfortunately, despite our big
                  dreams and good intentions, our programmes and interventions often miss the mark in impacting or changing the lives of orphaned & vulnerable
                  children (OVC).
                </p>
                <p>
                  Through the positive pressure of our donors at Mosaic over the years, we’ve had to monitor and evaluate (M&E) our programmes and interventions
                  in order to give thorough reports on the impact of their donations. Over the years I started appreciating and would even go as far as saying I
                  developed a passion for monitoring and evaluating the impact of our programmes and interventions on the lives of the children we work with.
                </p>
                <p>
                  This passion and a complete lack of existing software solutions led to the development of OVCmeasure, a data management platform for
                  non-profit organisations (NPO’s) and government organisations working with orphaned and vulnerable children.
                </p>
                <p>
                  OVC measure offers a platform for Beneficiary Data Management, Impact Measurement, Programme/ Intervention planning and Staff & Volunteer
                  Management.
                </p>
                <p>
                  During the 2nd half of 2021, after two years of development, OVCmeasure was launched. We have since signed up 11 organisations who actively
                  use the OVCmeasure platform to monitor the wellbeing of more than 2 400 children. Within Mosaic, OVCmeasure has empowered us to deliver even
                  more impactful, valuable services to our children and their caretakers.
                </p>
                <p>
                  We’ve cut ineffective programmes, changed some programmes, and added new ones… all based on data we got from OVCmeasure. This platform enables
                  us to direct our resources towards the programmes and interventions that are going to yield the most impact! If you want to know more about
                  this software, you are welcome to visit <a href="www.ovcmeasure.co.za">www.ovcmeasure.co.za</a>
                </p>
                <p>
                  In addition to launching OVCmeasure, we have also been able to support the start of Mosaic Deben, an independently registered NPO, following
                  the Mosaic Model of orphaned and vulnerable child care. They are officially part of the Mosaic family, but they take responsibility for their
                  own operations and fundraising. Our role is to serve and support them with a model, systems, procedures, programmes and curriculums!
                </p>
                <p>
                  This new season where we continue to serve our Mosaic children, but also serve the children of other organisations through the implementation
                  of the Mosaic Model and/or OVCmeasure software does make our vision feel a little bit more possible… maybe even within my lifetime! I hope you
                  will stick around to see every orphaned & vulnerable child in South Africa have a loving, caring family where they can achieve their God-given
                  potential!
                </p>
              </div>
              <p class="font-quentin" style="color: black; font-size: 28px">With love, Meyer.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- row end -->
  </div>
  <div class="spacer-30"></div>
</div>
