<div class="container">
  <!-- section title -->
  <div class="row">
    <h2 class="section-title wow fadeInUp font-open-sans-bold rounded section-header">HIGHLIGHTS OF 2021</h2>
  </div>

  <div class="spacer-60"></div>
  <div class="col row portfolio-wrapper margin-left-right bg-colour shadow-dark">
    <div *ngFor="let mosaicInAction of mosaicInActions" class="row">
      <!-- Video/image right side -->
      <ng-container *ngIf="mosaicInAction && mosaicInAction.isArrangement">
        <div class="col-sm-6">
          <h2 class="font-open-sans-regular title-color-text-left">{{ mosaicInAction.title }}</h2>
          <p class="font-open-sans-light" style="color: black" *ngFor="let par of mosaicInAction.parphs">{{ par }}</p>
          <p *ngIf="mosaicInAction.quote" class="font-open-sans-italic quote">“In a ‘don’t know’ moment, God can do a miracle.”</p>
        </div>
        <div *ngIf="mosaicInAction.imgPath" class="col-sm-6 bg-colour img-video-padding">
          <img style="margin-top: 20px; width: 100%; max-height: 280px" [src]="mosaicInAction.imgPath" />
          <span class="city-name-img-right">{{ mosaicInAction.cityText }}</span>
        </div>

        <div *ngIf="mosaicInAction.videoUrl" class="col-sm-6 bg-colour img-video-padding">
          <iframe
            width="100%"
            height="300px"
            [src]="mosaicInAction.videoUrl"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""
          ></iframe>
          <span class="city-name-video-right">{{ mosaicInAction.cityText }}</span>
        </div>
      </ng-container>
      <!-- Video/image left side -->
      <ng-container *ngIf="mosaicInAction && !mosaicInAction.isArrangement">
        <div *ngIf="mosaicInAction.imgPath" class="col-sm-6 bg-colour img-video-padding">
          <span class="city-name-img-left">{{ mosaicInAction.cityText }}</span>
          <img style="margin-top: 20px; width: 100%; max-height: 280px" [src]="mosaicInAction.imgPath" />
        </div>
        <div *ngIf="mosaicInAction.videoUrl" class="col-sm-6 bg-colour img-video-padding">
          <span class="city-name-video-left">{{ mosaicInAction.cityText }}</span>
          <iframe
            width="100%"
            height="300px"
            [src]="mosaicInAction.videoUrl"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""
          ></iframe>
        </div>
        <div class="col-sm-6">
          <h2 class="font-open-sans-regular title-color-text-right">{{ mosaicInAction.title }}</h2>
          <p class="font-open-sans-light" style="color: black" *ngFor="let par of mosaicInAction.parphs">{{ par }}</p>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="spacer-30"></div>
</div>
