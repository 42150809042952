<div class="container">
  <!-- section title -->
  <div class="row">
    <h2 class="section-title wow fadeInUp font-open-sans-bold section-header rounded">FAMILY BASED ORPHAN CARE</h2>
  </div>

  <div class="spacer-60"></div>

  <!-- portfolio filter (desktop) -->
  <!-- <ul class="portfolio-filter list-inline wow fadeInUp">
    <li class="list-inline-item" [ngClass]="{'current': workFilter === 'story1'}" (click)="workFilter = 'story1'">Story 1</li>
    <li class="list-inline-item" [ngClass]="{'current': workFilter === 'story2'}" (click)="workFilter = 'story2'">Story 2</li>
  </ul> -->

  <!-- portfolio filter (mobile) -->
  <!-- <div class="pf-filter-wrapper">
    <select class="portfolio-filter-mobile" (change)="onChange($event)">
      <option value="story1">Story 1</option>
      <option value="story2">Story 2</option>
    </select>
  </div> -->

  <div class="row video-player col bg-box padding-30 ">
    <iframe width="100%" height="300px" frameborder="0" allowfullscreen="" [src]="videoUrl"></iframe>
  </div>
  <!-- portfolio wrapper -->
  <div class="row margin-left portfolio-wrapper">
    <!-- portfolio item -->
    <div class="col row bg-box padding-30">
        <p class="font-open-sans-bold-italic" style="color: black">WHO IS MOSAIC?</p>
      <p class="intro-paragraph font-open-sans-semibold">
        We are not taking care of orphaned and vulnerable children. We partner with families in the communities of South Africa who are taking care of orphaned
        and vulnerable children (OVC).
      </p>
      <div class="font-open-sans-light" style="color: black">
        <p>
          We believe that as an organisation we don’t have to take care of any children, because South Africa (actually the whole of Africa) has a beautiful
          solution to the orphan care crisis. If a child becomes an orphan, or is considered vulnerable, then that child usually gets taken care of by his or
          her grandparents, aunt or an extended family member.
        </p>
        <p>
          It is therefore not necessary for that child to be taken into an institutionalised care setting, like a children’s home (orphanage). This is the
          beautiful part of the story. However, the sad part of this story is that, many of these families, who take care of the orphaned and vulnerable
          children, do not actually have the resources to take care of these children.
        </p>
        <p>
          Many of these caretakers don’t have adequate housing, they don’t have a job where they earn enough money to pay for food, school fees and the basic
          needs of their families.
        </p>
        <p>We partner with these families to support them with the resources that they need to take care of the children in their care.</p>
        <p>
          They already have the most important ‘ingredient’ necessary to take care of an orphaned child and that is – LOVE. They have already decided that they
          will take care of this child. So, our job is actually quite simple. We just come underneath or around or behind these families and we support them
          with resources.
        </p>
        <p>
          These resources include: creating a job, coaching the parents on life skills, afterschool programmes for the children to help them with their
          academics and development.
        </p>
        <p>
          We also provide opportunities like bursaries and the support needed to continue with a tertiary education. It could even include the possibility of
          moving into a three bedroom brick house. We are partnered with more than 80 different families in 4 different locations across South Africa.
        </p>
      </div>
      <p class="orphan-quote font-megaland">``We are dreaming to expand to many more locations in South Africa``</p>
    </div>
  </div>
  <div class="spacer-30"></div>
</div>
